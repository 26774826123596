.header-section-one {
    display: grid;
    justify-content: end;
    align-items: center;
    height: 10dvh;
    background-color: #fcfcfc;
    box-shadow: -6px 8px 10px rgba(81, 41, 10, 0.1), 0px 2px 2px rgba(81, 41, 10, 0.2);
    position: fixed;
    width: 100%;
    top: 0;
    transition: top 0.3s;
    z-index: 2;
}

.header-section-one p {
    position: absolute;
    height: 10dvh;
    margin-left: 2dvw;
    align-items: center;
    display: flex;
    object-fit: contain;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.1em;
    letter-spacing: 5px;
    cursor: default;
}
.header-logo{
    display: none;
}

.shine-text2 {
    background: linear-gradient(to left,
            #137E6D 20%,
            #292729 40%,
            #292729 60%,
            #137E6D 80%);
    background-size: 200%;
    color: transparent;
    background-clip: text;
    animation: shine 8s linear infinite;
}

nav {
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: 2dvw;
}


nav ul {
    list-style-type: none;
    overflow: hidden;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    display: inline-flex;
    text-align: center;
    align-items: center;

}

nav ul li {
    display: flex;
    text-align: center;
    align-items: center;

    /* border: solid black 3px; */
    height: 100%;
    width: 100%;
    padding: 1em;
    margin-top: -5px;

}

nav ul li a {
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 1em;
    letter-spacing: 2px;
    animation: fadeInAnimation 2s;
    text-decoration: none;
    color: black;
}

@keyframes shine {
    to {
        background-position: 200% center;
    }

}

@keyframes fadeInAnimation {
    0% {
        font-weight: 200;
    }

    100% {
        font-weight: 500;
    }
}

nav ul li a:hover {
    cursor: pointer;
    color: #137E6D;
    animation: fadeInAnimation 0.2s ease-in;
    font-weight: 500;

    text-decoration: none;
    transition-duration: 0.1s;
    transform: translateY(-2px);
}

nav ul li a:active {
    transform: translateY(0.125rem);
}


.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

  /*View: Phones.*/
@media screen and (max-width:450px) {
    nav ul li a {
        display: none;
    }

    .header-section-one p {
        font-size: .8em;
        letter-spacing: 2px;
        padding-left: 6em;
    }
        .header-logo {
            display: grid;
            object-fit: cover;
            height: 100%;
            object-position: center center;
            padding-left: .5em;


            position: absolute;
            object-fit: contain;
            cursor: default;
        }
    
}