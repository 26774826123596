.section-container.three, .article-section.three{
    background-color: #fcfcfc;
}

/*View: Phones.*/
@media screen and (max-width:450px) {

    .section-container.three,
    .section-container.three>main {
        height: 150dvh;
    }
}

