.section-container.four, .article-section.four{
    background-color: #f0f0f0;
}

.section-container.four, .section-container.four>main{
    height: 220dvh;
}

/*View: Phones.*/
@media screen and (max-width:450px) {
    .cards{
        position: relative;
        align-items: center;
        text-align: center;
        margin: 0.5em;
        padding: 1em;
        width: 27dvw;
        height: 200px;
        overflow: hidden;
    }
    .cards > div > p{
        font-size: 12px;
    }

    .section-container.four, .section-container.four>main{
        height: 220dvh;
    }
}
