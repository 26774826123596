
/* Main */
.wrapper,
.content{
  position: relative;
  width: 100%;
  z-index: 1;

}

.content{
  overflow-x: hidden;
}

.content::-webkit-scrollbar, .wrapper:-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.content, .image-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.content,
.section{
  width: 100%;
  height: 100dvh;
}


.section.four {
  height: 220dvh;
}

.content .section.one{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.image-container img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  
}

.image-container {
  width: 100%;
  height: 105dvh;
  top: -16px;
  left: 0;
  right: 0;
  perspective: 500px;
  z-index: 2;
  position: absolute;
  overflow-y: hidden;

}
/* Portrait: */
.frontPortrait{
  z-index: 1;
  position: absolute;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  background-color: transparent;
  
}

.frontPortrait.logo-left{
  padding: 0;
  margin: 0;
  margin-top: 2rem;
  margin-left: 2rem;
}

.frontPortrait.logo-center{
  font-size: 1.5rem;
  align-content: center;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  letter-spacing: 10px;
}
.MainTitle{
  font-size: 5rem;
}
.SecondTitle{
  font-size: 2rem;
}

@keyframes blur {
  0%{
    filter:blur(0);
  }
  25%{
    filter:blur(1px);
  }
  50%{
    filter:blur(5px);
  }
  75%{
    filter:blur(1px);
  }
  25%{
    filter:blur(0);
  }
}

.pheader span:nth-child(1){
  animation-delay: 0s;
}
.pheader span:nth-child(n){
  animation-delay: 0.4s;
}
.pheader span:nth-child(3){
  animation-delay: 0.8s;
}
.pheader span:nth-child(4){
  animation-delay: 1s;
}
.pheader span:nth-child(5){
  animation-delay: 1.4s;
}
.pheader span:nth-child(6){
  animation-delay: 1.8s;
}
.pheader span:nth-child(7){
  animation-delay: 2.2s;
}

/* Portrait: Color Efect*/
@keyframes shine {
  to {
    background-position: 200% center;
  }

}

.shine-text {
  font-size: 1em;
  background: linear-gradient(
    to left,
    #ffffff 20%,
    #2B2C2C 40%,
    #2B2C2C 60%,
    #ffffff 80%
  );
  background-size: 200%;
  color: transparent;
  background-clip: text;
  animation: shine 8s linear infinite;
  letter-spacing: 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}



/*View: Phones.*/
@media screen and (max-width:450px) {
  .shine-text {
    align-content: center;
    text-align: center;
  }

  .frontPortrait.logo-center {
    font-size: 1rem;
    letter-spacing: 5px;
  }

  .SecondTitle {
    font-size: 1rem;
  }

  .section.two {
    height: 130dvh;
  }

  .section.three {
    height: 150dvh;
  }

  .section.four {
    height: 220dvh;
  }
}