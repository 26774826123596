.section-container.two, .article-section.two{
    background-color: #F0F0F0;
}

.header-section.two>img {
    display: grid;
    object-fit: cover;
    width: 58px;
    height: 58px;

    position: absolute;
    left: 49%;
    top: 5%;
}



/*View: Phones.*/
@media screen and (max-width:450px) {

    .section-container.two,
    .section-container.two>main {
        height: 130dvh;
    }

    .header-section.two>img {
        top: 3%;
    }
}



