:root{
    --sl-width: 50px;
    --sl-height: 30px;
    --sl-border-color: gray;

    --sl-border-radius: calc(var(--sl-height) / 2);
    --sl-flag-size:calc(var(--sl-height) - 6px);
    --sl-transformX: calc(var(--sl-width) - var(--sl-flag-size) - 6px);
}

.switch-container,
.switch {
    border-radius: var(--sl-border-radius);
}

.switch-container {
    height: var(--sl-height);
    width: var(--sl-width);
    border: 1px solid var(--sl-border-color);
    position: relative;
    box-sizing: border-box;
    cursor:pointer;

}

.switch {
    height: var(--sl-flag-size);
    width: var(--sl-flag-size);
    position: absolute;
    top: 2px;
    left: 2px;
    transition: 1s;
    background-size: cover;
    background-image: url(../images/united-states.png)
}


.switch.on {
    left: var(--sl-transformX);
}
