.content{
    position: relative;
    width: 100%;
    height: 100dvh;
    overflow-x: hidden;
}

main{
    display: inline-flex;
    height: 100dvh;
    overflow: hidden;
    background-color: #fcfcfc;
    width: 100%;
}

.tab{
  display: none;
}

.article-section-one{
    display: grid;
    align-items: center;
    align-content: center;
    padding-left: 1em;
    font-weight: 600;
    font-family: Consolas;
    width: 55%;
    background-color: #fcfcfc;
    box-shadow:  inset 0 3em 3em -3em rgba(81, 41, 10, 0.1),
                 0 0 0 2px rgba(81, 41, 10, 0.2);
}

.article-section-one > p{
  font-size: 1.4em;
}

.MainTitle{
  font-weight: 700;
  font-size: 3em !important;
  padding: 0;
  margin: 0;
}
#MainTitleDevice{
  display: none;
}

/*Button*/
/* Botón */
article a {
  text-decoration: none;
  font-family:Arial, Helvetica, sans-serif;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #046151 0 -3px 0 inset;
  border-width: 0;
  appearance: none;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: #fff;
  background-color: #137E6D;
  display: inline-flex;
  height: 48px;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  width: 215px;
  position: relative;
  transition: box-shadow .15s, transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 30px;
  letter-spacing: 1px;
}

article a:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

article a:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #046151 0 -3px 0 inset;
  transform: translateY(-2px);
}

article a:active {
  background-color: #046151;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transform: translateY(0.125rem);
}

.shine{
position: absolute;
left: 0;
top: 0;
right: 0;
bottom: 0;
width: 100%;
height: 100%;
transform: skew(30deg) translateX(-120%);
background-color: rgba(255,255,255,0.3);
animation: shine2 2s ease infinite;
}
  
@keyframes shine2 {
  0% {
    transform: skew(30deg) translateX(-120%);
  }

  30% {
    transform: skew(30deg) translateX(120%);
  }

  100% {
    transform: skew(30deg) translateX(120%)
  }
}

/*-----------------------------*/

aside{
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: #fcfcfc;
    width: 50%;
    overflow: hidden;
    box-shadow:  inset 0 3em 3em -3em rgba(81, 41, 10, 0.1);

}

aside > div{
  display: grid;
  margin-top: 10vh;
  width: 100%;
  height: 100%;
  overflow: hidden;

}

aside > div > img{
    display: flex;
    object-fit: cover;
    width: 105%;
    height: 100%;
    margin-top: -5vh;
}

#ptcMain1, #ptcMain3{
  display: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

  /*View: Phones.*/
  @media screen and (max-width:450px) {
   
 
    .article-section-one{
      align-items: center;
      padding-left: 0.5em;
      font-weight: 600;
      width: 75%; 
      background-color: #fcfcfc;
  }

  .MainTitle{
    font-weight: 700;
    font-size: 1.8em !important;
    padding: 0;
    margin: 0;
  }

  #MainTitleSpan{
    display: none;
  }
  #MainTitleDevice{
    display: block;
  }

  aside{
    width: 25%;
    padding-right: 1.5em;
    background-color: #fcfcfc;
}


#ptcMain1, #ptcMain3{
  display: block;
}

.gallery-container{
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  border: solid black 1px;
}

.gallery-item{
  height: 80%;
  opacity: 0;
  position: absolute;
  transition: all 0.8s ease-in-out;
  width: 25%;
  z-index: 0;
  border-radius: 15px;
  object-fit:cover;
}

.gallery-item-1, .gallery-item-3{
  height: 70%;
  opacity: .7;
  width: 20%;
  z-index: 3;
}
.gallery-item-1{
  left: 40%;
  transform: translateX(-50%);
}

.gallery-item-3{
  left:80%;
  transform: translateX(-50%);
}


.gallery-item-2{
  box-shadow: -2px 5px 10px 6px rgba(0,0,0,0.5);
  height: 85%;
  width: 30%;
  left: 55%; 
  transform: translateX(-50%);
  opacity: 1;
  z-index: 4;
}


@media (max-width: 1000px){
  .gallery-container{
   width: 60vw;
  }
}

/*View: Phones.*/
@media screen and (max-width:450px) {
  .tab {
    display: inline-flex;
    position: absolute;
    z-index: 5;
    height: 110px;
    width: 140px;

    top: 80%;
    left: 30%;
    transform: translate(-50%, -50%);
    transform: rotate(-30deg);
  }

  .tab>img {
    width: 80%;
    height: 80%;
  }
}
}