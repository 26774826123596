.contain{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;
}

.cards{
    position: relative;
    align-items: center;
    text-align: center;
    margin: 0.5em;
    padding: 1em;
    width: 18dvw;
    height: 14dvw;
    overflow: hidden;
}

.cards.btn{
    border: black 1px solid;
    width: 10dvw;
    height: 10dvw;

    border-radius: 10px;
    cursor: pointer;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    border-width: 0;
    appearance: none;
    align-items: center;;
    justify-self: center;
    box-sizing: border-box;
    background-color: #fff;
    line-height: 1;
    list-style: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
}

.cards.btn:focus{
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.cards.btn:hover{
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    transform: translateY(-2px);
}

.cards.btn:active {
    background-color: #D6D6E7;
    box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
    transform: translateY(0.125rem);
}

.cards.btn > img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center center;
}
.cards > img{
    object-fit: cover;
    width: 90px;
    height: 90px;
}

.cards.portfolio{
    perspective: 1000px;
    width: 41dvw;
    height: 60dvh;
    margin: 0;
    padding: 0;

}

.flip-box{
    position: relative;
    width: 100%;
    height: 75%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    margin-bottom: 5px;
}

.flip-box:hover{
    transform: rotateY(180deg);
}

.flip-front, .flip-back{
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 10px;
}

.flip-back{
    transform: rotateY(180deg);
}

.flip-front>img, .flip-back>img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    cursor: pointer;
}

.cards > div > p{
    width: 100%;
    padding: 0;
    margin: 0;
}

.description2{
    font-size: 14px;
}


/*View: Phones.*/
@media screen and (max-width:450px) {
    .cards {
        position: relative;
        align-items: center;
        text-align: center;
        margin: 0.5em;
        padding: 1em;
        width: 27dvw;
        height: 200px;
        overflow: hidden;

    }

    .cards>div {
        font-size: 12px;

    }

    .cards>div>p {
        font-size: 12px;
    }

    .cards.portfolio {
        perspective: 1000px;
        width: 100%;
        height: 250px;
        padding-bottom: 10px;

    }

    .flip-front,
    .flip-back {
        width: 81dvw;
        height: 175px;
        border-radius: 20px;
    }

    .contain{
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        overflow: hidden;
    }
    
    .cards.btn {
        width: 95px;
        height: 95px;
    }
}