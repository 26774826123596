.section-container {
  position: relative;
  width: 100%;
  height: 100dvh;
  overflow: hidden;
}

.section-container>.header-section{
  display: grid;
  align-items: center;
  height: 20dvh;
}

.section-container>main {
  display: flex;
  overflow: hidden;
  height: 80dvh;
}

.header-section p{
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: 2.4em;
  cursor: default;
  font-weight: 700;
  letter-spacing: 3px;
  padding-top: 1em;
 }

 .article-section{
  display: grid;
  align-content: start;
  text-align: justify;
  width: 100%;
  font-weight: 600;
  font-size: 1.2em;
  padding: 2em;
  color: #383a3a;
}

 